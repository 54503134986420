<template>
  <div>
    <b-row align-v="stretch">
      <b-col class="mb-3" order="1" cols="12" md="6" lg="4" xl="3">
        <div class="h-100 d-flex flex-column">
          <b-row class="same-rent mb-3">
            <b-col>
              <dashboard-big-number-a
                  title="Total de inscritos"
                  popover="Total de inscritos"
                  class="h-100"
              >{{ totalInscritos }}
              </dashboard-big-number-a>
            </b-col>
          </b-row>
          <b-row class="same-rent mb-3">
            <b-col>
              <dashboard-big-number-a
                  title="Usuário online neste momento"
                  popover="Usuário online neste momento"
                  class="h-100"
              >{{ totalOnline }}
              </dashboard-big-number-a>
            </b-col>
          </b-row>
          <b-row class="same-rent">
            <b-col>
              <dashboard-big-number-a
                  title="Pico de usuários simultâneos"
                  popover="Pico de usuários simultâneos"
                  class="h-100"
              >{{ picoUsuarios }}
              </dashboard-big-number-a>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col class="mb-3" order="2" order-xl="1" cols="12" xl="6">
        <dashboard-area-chart
            title="Usuários online"
            popover="Usuários online no momento"
            series-title="online"
            :series-data="chartDataUsuarioOnlineData"
            :series-label="chartDataUsuarioOnlineLabel"
            class="h-100"
        />
      </b-col>
      <b-col class="mb-3" order="1" cols="12" md="6" lg="8" xl="3">
        <dashboard-radial-chart
            title="Taxa de usuários online"
            popover="Taxa de usuários online no momento"
            class="h-100"
            series-name="Online"
            :series-value="taxaUsuariosOnline"
        />
      </b-col>
    </b-row>
    <b-row align-v="stretch">
      <b-col class="mb-3" cols="12" sm="6" lg="3">
        <dashboard-big-number-a
            title="Total de perguntas recebidas"
            popover="Total de perguntas recebidas"
            class="h-100"
        >{{ totalPerguntas }}
        </dashboard-big-number-a>
      </b-col>
      <b-col class="mb-3" cols="12" sm="6" lg="3">
        <dashboard-big-number-a
            title="Total de quiz respondidos"
            popover="Total de quiz respondidos"
            class="h-100"
        >{{ totalQuiz }}
        </dashboard-big-number-a>
      </b-col>
      <b-col class="mb-3" cols="12" sm="6" lg="3">
        <dashboard-big-number-a
            title="Total de enquetes respondidas"
            popover="Total de enquetes respondidas"
            class="h-100"
        >{{ totalEnquetes }}
        </dashboard-big-number-a>
      </b-col>
      <b-col class="mb-3" cols="12" sm="6" lg="3">
        <dashboard-big-number-a
            title="Total de palavras núvem"
            popover="Total de palavras núvem"
            class="h-100"
        >{{ totalPalavrasNuvem }}
        </dashboard-big-number-a>
      </b-col>
    </b-row>
    <b-row align-v="stretch">
      <b-col class="mb-3" cols="12" xl="7">
        <dashboard-big-number-a
            title="Total de reações"
            popover="Total de reações"
        >
          <div class="d-flex justify-content-between">
            <div v-for="(item, index) in totalReacoesList" :key="'reacao-'+index">
              {{ item.reacao }} {{ item.total }}
            </div>
          </div>
        </dashboard-big-number-a>
      </b-col>
      <b-col class="mb-3" cols="12" xl="5">
        <dashboard-big-number-b
            class="h-100"
            :value-list="detailList3"
        />
      </b-col>
    </b-row>
    <b-row align-v="stretch">
      <b-col class="mb-3" cols="12" xl="9">
        <dashboard-detail-line-chart
            title="Evolução de inscritos"
            popover="Evolução de inscritos"
            :series-data="evolucaoInscritosGraph.dataList"
            :series-label="evolucaoInscritosGraph.labelList"
            series-title="Inscritos"
            :detail-list="evolucaoInscritosDetail"
        />
      </b-col>
      <b-col class="mb-3" cols="12" xl="3">
        <dashboard-detail-list
            class="h-100"
            :list="detailList"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mb-3" cols="12" lg="9">
        <dashboard-table-data
            title="Ranking Gamificação"
            popover="Ranking Gamificação"
            :items="rankingGamificacaoList"
        />
      </b-col>
      <b-col class="mb-3" cols="12" lg="3">
        <dashboard-detail-list
            :list="detailList2"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DashboardAreaChart from '@/components/dashboards/dashboard-area-chart'
import DashboardRadialChart from '@/components/dashboards/dashboard-radial-chart'
import DashboardBigNumberA from '@/components/dashboards/dashboard-big-number-a'
import DashboardBigNumberB from '@/components/dashboards/dashboard-big-number-b'
import DashboardDetailLineChart from '@/components/dashboards/dashboard-detail-line-chart'
import DashboardDetailList from '@/components/dashboards/dashboard-detail-list'
import DashboardTableData from '@/components/dashboards/dashboard-table-data'
import { subscribers } from '@/modules/dashboards/http/subscribers'
import { displayPercent } from '@/modules/dashboards/toolbox/displayPercent'
import { subscribersGraph } from '@/modules/dashboards/http/subscribersGraph'
import { usersOnline } from '@/modules/dashboards/http/usersOnline'
import { usersOnlineRate } from '@/modules/dashboards/http/usersOnlineRate'
import { usersOnlineGraph } from '@/modules/dashboards/http/usersOnlineGraph'
import { totalMetricsEvent } from '@/modules/dashboards/http/totalMetricsEvent'
import { reactEmojis } from '@/modules/dashboards/http/reactEmojis'
import { ranking } from '@/modules/dashboards/http/ranking'

import defaultAvatar from '@/assets/images/users/default.webp'

const INTERVAL_60_SECONDS = 120000

export default {
  name: 'RealTime',
  components: {
    DashboardTableData,
    DashboardDetailList,
    DashboardDetailLineChart,
    DashboardBigNumberB,
    DashboardBigNumberA,
    DashboardRadialChart,
    DashboardAreaChart
  },
  data: () => ({
    updateTimeout: null,
    chartDataUsuarioOnlineData: [],
    chartDataUsuarioOnlineLabel: [],
    taxaUsuariosOnline: 0,
    totalInscritos: '',
    totalOnline: '',
    picoUsuarios: '',
    totalPerguntas: '',
    totalQuiz: '',
    totalEnquetes: '',
    totalPalavrasNuvem: '',
    totalReacoesList: [],
    evolucaoInscritosGraph: {
      dataList: [],
      labelList: []
    },
    evolucaoInscritosDetail: [
      {
        title: 'Última hora',
        value: 0,
        emphasis: ''
      },
      {
        title: 'Últimas 24h',
        value: 0,
        emphasis: ''
      },
      {
        title: 'Últimos 7 dias',
        value: 0,
        emphasis: ''
      }
    ],
    detailList: [
      {
        label: 'Posts newsfeed',
        value: '',
        info: 'Total de postagens'
      },
      {
        label: 'Total de curtidas',
        value: '',
        info: 'Total de Curtidas em geral'
      },
      {
        label: 'Total de comentários',
        value: '',
        info: 'Total de Comentários em geral'
      }
    ],
    detailList2: [
      {
        label: 'Pesquisa de satisfação respondidas',
        value: '',
        // valueEmphasis: '25%',
        info: 'Quantidade de usuários que responderam a Pesquisa de satisfação'
      },
      {
        label: 'Certificados gerados',
        value: '',
        info: 'Quantidade de usuários que geraram certificados'
      }
    ],
    detailList3: [
      {
        title: 'Total de mensagens',
        value: 'Indisponível',
        info: 'Total de mensagens recebidas pelo sistema'
      },
      {
        title: 'Tempo médio de conexão',
        value: 'Indisponível',
        info: 'Tempo médio de conexão com o sistema'
      }
    ],
    rankingGamificacaoList: [],
    onUpdate: false
  }),
  methods: {
    async updateValues () {
      if (this.onUpdate) {
        return
      }
      this.onUpdate = true
      await Promise.allSettled([
        this.updateSubscribers(),
        this.updateSubscribersGraph(),
        this.updateUsersOnline(),
        this.updateUsersOnlineRate(),
        this.updateUsersOnlineGraph(),
        this.updateTotalMetricsEvent(),
        this.updateReaction(),
        this.updateRanking()
      ])
      this.onUpdate = false
    },
    async updateSubscribers () {
      const subscribersTotal = await subscribers()
      this.totalInscritos = subscribersTotal.total
      this.evolucaoInscritosDetail[0].value = subscribersTotal.last1h
      this.evolucaoInscritosDetail[0].emphasis = displayPercent(subscribersTotal.last1hGrowth)
      this.evolucaoInscritosDetail[1].value = subscribersTotal.last24h
      this.evolucaoInscritosDetail[1].emphasis = displayPercent(subscribersTotal.last24hGrowth)
      this.evolucaoInscritosDetail[2].value = subscribersTotal.last7d
      this.evolucaoInscritosDetail[2].emphasis = displayPercent(subscribersTotal.last7dGrowth)
    },
    async updateSubscribersGraph () {
      const data = await subscribersGraph()
      this.evolucaoInscritosGraph.dataList = data.list
      this.evolucaoInscritosGraph.labelList = data.label
    },
    async updateUsersOnline () {
      const data = await usersOnline()
      this.totalOnline = data.current
      this.picoUsuarios = data.peak
    },
    async updateUsersOnlineRate () {
      const data = await usersOnlineRate()
      this.taxaUsuariosOnline = data.rate
    },
    async updateUsersOnlineGraph () {
      const data = await usersOnlineGraph()
      this.chartDataUsuarioOnlineData = data.list
      this.chartDataUsuarioOnlineLabel = data.label
    },
    async updateTotalMetricsEvent () {
      const data = await totalMetricsEvent()
      this.totalPalavrasNuvem = data.wordCloud
      this.totalPerguntas = data.asking
      this.totalQuiz = data.quiz
      this.totalEnquetes = data.poll
      this.detailList[0].value = data.postsFeed
      this.detailList[1].value = data.postsLikes
      this.detailList[2].value = data.postsComments
      this.detailList2[0].value = data.survey
      this.detailList2[1].value = data.certs
    },
    async updateReaction () {
      const data = await reactEmojis()
      this.totalReacoesList = [
        { reacao: '👍', total: data.likes },
        { reacao: '😍', total: data.love },
        { reacao: '🚀', total: data.rocket },
        { reacao: '😮', total: data.wow },
        { reacao: '👏', total: data.hands },
        { reacao: '😆', total: data.incredible }
      ]
    },
    async updateRanking () {
      const data = await ranking()
      this.rankingGamificacaoList = data.map(item => ({
        pic_url: item.avatar ?? defaultAvatar,
        name: item.name,
        email: item.email,
        points: item.totalPoints
      }))
    },
    autoRefresh () {
      this.updateTimeout = setTimeout(async () => {
        await this.updateValues()
        this.autoRefresh()
      }, INTERVAL_60_SECONDS)
    }
  },
  created () {
    this.updateValues()
    this.autoRefresh()
  },
  beforeDestroy () {
    clearTimeout(this.updateTimeout)
  }
}
</script>

<style scoped>
.same-rent {
  flex: 1 1 calc(100% / 3)
}
</style>
